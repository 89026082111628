<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title class="mb-n2">
        Queue Statistics (Open Status)
      </b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-n2">
        Updated {{ data.queryTime | moment('timezone', 'UTC', 'from') }}
        <b-progress
          v-if="data.queryCount === 0"
          class="mt-n3"
          :value="barValue"
          max="100"
        />
      </b-card-text>
    </b-card-header>
    <b-card-body
      v-if="data.queryCount != 0"
    >
      <b-row class="match-height">
        <b-col
          v-for="queue in data.results"
          :key="queue.name"
          cols="12"
          md="3"
          class="mt-0"
        ><div @click="routeToQueue(queue.boardName)">
          <statistic-card-vertical
            :statistic="queue.countOpen"
            :statistic-title="queue.boardName"
            color="info"
            class="mb-n1 text-truncate"
          /></div>
        </b-col>
      </b-row>
    </b-card-body></b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BProgress,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BProgress,
    StatisticCardVertical,
  },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      barValue: 0,
      timer: 0,
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) { this.barValue = 0 }
      this.barValue += 5
    }, 300)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.barValue = null
  },
  methods: {
    routeToQueue(queue) {
      this.$router.push(`/query/queue?queue=${encodeURIComponent(queue)}`)
    },
  },
}
</script>
