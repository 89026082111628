<template>
  <section id="lookup-queue">
    <b-row class="match-height">
      <b-col
        cols="12"
        class="my-0"
      >
        <queue-listing :data="dataQueueStats" />
      </b-col>
    </b-row>
    <b-row><b-col cols="12">
      <h1>{{ queue }}</h1>
    </b-col></b-row>
    <b-row class="match-height">
      <!-- Open Tickets -->
      <b-col
        v-if="dataTickets"
        lg="12"
      >
        <tickets :table-data="dataTickets" />
      </b-col>
      <!--/ Open Tickets -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import QueueListing from './QueueListing.vue'
import Tickets from '../../components/_ticket/TicketsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    QueueListing,
    Tickets,
  },
  data() {
    return {
      timer: 0,
      barValue: 0,
      queue: '',
      searching: false,
      dataTickets: {
        queryTime: {
          value: '2021-01-01T00:00:00.000+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ticketNo: 0,
            status: '',
            statusColor: '',
            operator: [{ name: '', img: '' }],
            startDate: '',
            updateDate: '',
            summary: '',
            company: '',
            contact: '',
            queue: '',
          },
        ],
      },
      dataQueueStats: {
        queryTime: {
          value: '2021-01-01T00:00:00.000+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            boardName: '',
            countOpen: 0.0,
            countPending: 0.0,
            countStale: 0.0,
            countClosed: 0.0,
            countClosedToday: 0.0,
            openTickets: {},
          },
        ],
      },
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query.queue': function () {
      this.lookupQueueTickets(this.$route.query.queue)
    },
  },
  created() {
    if (this.$route.query.queue) {
      this.lookupQueueTickets(this.$route.query.queue)
    }
    if (!this.$route.query.queue) {
      this.$router.replace({ path: '/query/queue?queue=Customer Support' })
    }
  },
  mounted() {
    this.getQueueData()
  },
  methods: {
    async getQueueData() {
      await this.$http
        .get(
          'https://asi-api2.azure-api.net/ticket/service/boardStats?key=836715d7f31643618225af5af3141717',
        )
        .then(response => {
          this.dataQueueStats = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async lookupQueueTickets(queue) {
      this.dataTickets = {
        queryTime: {
          value: '2021-01-01T00:00:00.000+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ticketNo: 0,
            status: '',
            statusColor: '',
            operator: [{ name: '', img: '' }],
            startDate: '',
            updateDate: '',
            summary: '',
            company: '',
            contact: '',
            queue: '',
          },
        ],
      }
      this.searching = true
      this.queue = queue
      await this.$http
        .get(
          // `https://asi-api2.azure-api.net/ticket/service/queue?id=${queue}&filter=open&key=836715d7f31643618225af5af3141717`,
          `https://asi-api2.azure-api.net/psa/query/ticket?by=queueName&value=${encodeURIComponent(queue)}&status=open&key=836715d7f31643618225af5af3141717`,
        )
        .then(response => {
          this.dataTickets = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.searching = false
    },
  },
}
</script>
