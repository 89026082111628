<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  ><b-overlay
    :show="show"
    rounded="sm"
  >
    <b-table
      :items="tableData.results"
      responsive
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      class="mb-0"
    >
      <!-- Status -->
      <template #cell(status)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            size="10"
            :variant="data.item.statusColor"
          >
            <feather-icon
              size="1"
              icon="DropletIcon"
            />
          </b-avatar>
          <span class="pl-1">{{ data.item.status }}</span>
        </div>
      </template>
      <template #cell(ticketNo)="data">
        <div class="d-flex align-items-center">
          <!-- <router-link :to="'/query/ticket/' + data.item.ticketNo"> -->
            {{ data.item.ticketNo }}
          <!-- </router-link> -->
        </div>
      </template>
      <!-- summary -->
      <template #cell(summary)="data">
        <div
          class="d-flex align-items-center"
        >
          <div style="width: 100%">
            <div
              class="h6"
              style="width: 100%; white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:450px;"
            >
              {{ data.item.summary }}
            </div>
            <div class="text-uppercase text-muted font-small-2">
              {{ data.item.contact }} at {{ data.item.company }}
            </div>
          </div>
        </div>
      </template>
      <!-- assigned resources -->
      <template #cell(resources)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            v-if="data.item.operator.length == 1"
            v-b-tooltip.hover.bottom="data.item.operator[0].name"
            :src="data.item.operator[0].img"
            class="pull-up"
          />
          <b-avatar-group
            v-if="data.item.operator.length > 1"
            size="34px"
            style="direction: rtl;"
          >
            <b-avatar
              v-for="avatar in data.item.operator"
              :key="avatar.name"
              v-b-tooltip.hover.bottom="avatar.name"
              :src="avatar.img"
              class="pull-up"
            />
          </b-avatar-group>
        </div>
      </template>
      <template #cell(startDate)="data">
        <div class="d-flex align-items-center">
          {{ data.item.startDate | moment("MMM Do") }}
        </div>
      </template>
      <template #cell(updateDate)="data">
        <div class="d-flex align-items-center">
          {{ data.item.updateDate | moment("from") }}
        </div>
      </template>
      <template #cell(queue)="data">
        <div class="text-truncate">
          {{ data.item.queue }}
        </div>
      </template>
    </b-table>
  </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BAvatarGroup, VBTooltip, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BAvatarGroup,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tableData: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      barValue: 0,
      timer: 0,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        { key: 'ticketNo', label: 'TICKET NO.', sortable: true },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'summary', label: 'SUMMARY' },
        { key: 'resources', label: 'RESOURCES' },
        { key: 'queue', label: 'QUEUE', sortable: true },
        { key: 'startDate', label: 'START DATE', sortable: true },
        { key: 'updateDate', label: 'LAST UPDATED', sortable: true },
      ],
    }
  },
  watch: {
    tableData(val) {
      if (val.queryCount === 0 || val.queryCount === null) {
        this.show = true
      }
      if (val.queryCount > 0) {
        this.show = false
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) { this.barValue = 0 }
      this.barValue += 5
    }, 300)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.barValue = null
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>

<style>
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
</style>
